
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/tooltip.scss';
    @import '~/scss/slider.scss';
  
.container {
  max-width: 82.5rem;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 16px;

  @include breakpoint(medium) {
    padding: 60px 30px;
  }

  @include breakpoint(large) {
    padding: 60px;
  }

  .headline {
    margin-top: 0;
    margin-bottom: 0;
    font-family: $font-axiforma;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;
  }

  .subhead {
    //styleName: body/md;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #333;
    text-align: center;
    margin-top: 0;

    @include breakpoint(medium) {
      margin-bottom: 20px;
    }
  }

  .threeItemContentWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    @include breakpoint(medium) {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 48px;
    }
  }

  .fourItemContentWrapper {
    @include breakpoint(medium) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(large) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  svg {
    position: relative;
    top: 48px;
  }

  .content {
    margin-left: 60px;
    color: #333;
  }

  .heading {
    margin: 0;
    color: $color-dark-gray;
    font-family: $font-axiforma;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .subheading {
    margin: 8px 0 0 0;
    color: $color-dark-gray;
    font-size: 14px;

    a:link {
      color: $color-dark-gray;
    }

    a:visited {
      color: $color-dark-gray;
    }
  }

  .disclaimer {
    color: #53565a;
  }

  .buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include breakpoint(medium) {
      align-items: center;
    }
  }

  .btn {
    width: 100%;
    height: 48px;
    padding: 16px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-family: $font-axiforma;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    margin-top: 0;
    border: none;
    background: #de3700;

    @include breakpoint(medium) {
      margin-bottom: 14px;
      width: 213px;
    }

    &:hover,
    &:focus {
      border: none;
      background: #cc461a;
      color: $color-white;
    }
  }
}
