
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/tooltip.scss';
    @import '~/scss/slider.scss';
  
.container {
  max-width: 1440px;
  min-height: 700px;
  margin: 0 auto;
  background-image: url('/images/heroes/offer-hero-hn-redesign-v1.jpg');
  background-position: center;
  background-size: cover;

  @include breakpoint(medium) {
    min-height: 724px;
  }

  @include breakpoint(large) {
    min-height: 640px;
  }

  .butterfly {
    margin-right: 20px;
    @include breakpoint(medium) {
      margin-right: 50px;
    }
  }

  .globalBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin-bottom: -9px;
    background-image: url('/images/shapes/j3-global-mobile.png');
    background-repeat: no-repeat;
    background-size: 100% 48px;
    color: #fff;
    font-family: $font-axiforma;
    gap: 6px;

    @include breakpoint(medium) {
      height: 80px;
      background-image: url('/images/shapes/j3-global-desktop.png');
      background-size: 100% 80px;
    }

    .head {
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      margin-left: 15px;
      @include breakpoint(medium) {
        font-size: 22px;
        line-height: 24px;
      }
      @include breakpoint(large) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .subHead {
      font-weight: 600;
      font-size: 24px;
      line-height: 38px;
      max-width: 373px;
    }

    .bannerIcon {
      margin: 0 24px;
      width: 40px;
      height: 40px;
      @include breakpoint(medium) {
        width: 60px;
        height: 60px;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.headers {
  max-width: 617px;
}

.highPropHeaders {
  @include breakpoint(large) {
    max-width: 565px;
    margin-right: 36px;
  }
}

.heading {
  margin: 0;
  font-family: $font-axiforma;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.15;
  text-align: left;
  @include breakpoint(large) {
    margin-top: 70px;
    font-size: 48px;
  }

  .heroSup {
    font-size: 75%;
  }
}

.removeTopMargin {
  margin-top: 0;
}

.subheading {
  margin-bottom: 24px;
  color: $color-dark-gray;
  font-weight: 400;
  line-height: 1.35;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 1008px;
  max-height: 364px;
  margin-right: auto;
  margin-left: auto;
  padding: 24px 16px;

  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: center;
    padding: 48px 32px;
  }

  @include breakpoint(large) {
    padding: 64px 32px;
  }

  .offerBox {
    padding: 24px;
    padding-top: 0;
    border-radius: 8px;
    box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

    @include breakpoint(medium) {
      position: inherit;
      min-width: 276px;
      max-width: 328px;
      height: 100%;
      margin-left: 48px;
    }
  }

  .offerBoxMin {
    padding: 20px;
    padding-top: 0;
    @include breakpoint(medium) {
      margin-top: 50px;
    }
  }

  .offerHeading {
    max-width: 189px;
    max-height: 94px;
    margin: 0;
    font-family: $font-axiforma;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    @include breakpoint(medium) {
      font-size: 16px;
    }
  }

  .offerSubheading {
    margin: 5px 0 0 0;
  }

  .offerBoxOldPrice {
    position: relative;
    top: -0.2em;
    margin-right: 2px;
    color: $color-matterhorn;
    font-family: $font-proxima;
    font-size: 20px;
    line-height: 1.2;
    text-decoration: line-through;
  }

  .offerBoxNewPrice {
    margin-right: 2px;
    color: #ff5820;
    font-family: $font-proxima;
    font-size: 40px;
    line-height: 1.35;
  }

  .offerBoxPerMonth {
    position: relative;
    top: -0.2em;
    color: $color-dark-gray;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
  }

  .bodySup {
    position: relative;
    top: -0.8em !important;
    color: #0e4275;
    font-size: 10px;

    &__header {
      position: relative;
      top: -0.6em;
      font-size: 27px;
    }

    &__newPrice {
      position: relative;
      top: -0.7em;
      font-size: 24px;
    }
  }

  .offerEnd {
    position: relative;
    top: -6px;
    color: #53565a;
    font-family: $font-primary;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4;
  }

  .addressContainer {
    margin: 15px 0;
    border-radius: 30px;
  }

  .addressLabel {
    color: $color-dark-gray;
    font-family: $font-primary;
    font-size: 10px;
    font-weight: 700;
    line-height: 1.4;
  }

  .callNowCta {
    width: 100%;
    height: 48px;
    margin: auto;
    border: 2px solid $color-dark-blue;
    border-radius: 30px;
    color: $color-dark-blue;
    font-family: $font-proxima;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;

    &:hover,
    &:focus {
      border: none;
      background: #113c72;
      color: $color-white;

      svg > path {
        fill: $color-white;
      }
    }

    &:focus {
      outline: 2px dashed #787878;
    }
  }

  .callNowCtaText {
    margin: 0 6px 0 8px;
  }
}

.offerContainer {
  display: flex;
  justify-content: center;
  max-height: 68px;
  margin: auto;
  margin-top: 24px;
  color: #fafbfd;

  @include breakpoint(medium) {
    display: flex;
    max-height: 94px;
    margin-bottom: 16px;
  }

  .offerValueBox {
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 40px;
    margin-right: 16px;
    border-radius: 4px;
    background: #003a4e;
    font-family: $font-proxima;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

    @include breakpoint(medium) {
      width: 78px;
      height: 50px;
      margin: auto 16px auto auto;
      margin-right: 16px;

      font-size: 25px;
    }

    .offerValue {
      font-family: Proxima Nova;
      font-weight: 00;
      line-height: 40px;
      text-align: center;
    }

    .offerSmallText {
      width: 78px;
      margin-top: -5px;
      font-family: Open Sans;
      font-size: 10px;
      font-weight: 300;
      text-align: center;
    }
  }
}
