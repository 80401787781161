
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/tooltip.scss';
    @import '~/scss/slider.scss';
  
.container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  text-align: -webkit-center;
}

.headers {
  align-self: center;
}

.heading {
  margin-top: 24px;
  font-family: $font-axiforma;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  @include breakpoint(medium) {
    margin-top: 40px;
  }
}

.subheading {
  color: white;
  line-height: 1.37;
}

.content {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  padding: 48px 16px;

  @include breakpoint(medium) {
    padding: 64px 32px;
  }

  @include breakpoint(large) {
    padding: 64px;
  }

  .addressContainer {
    margin: 16px 0 0 0;
  }

  .addressLabel {
    color: white;
    font-family: $font-primary;
    font-size: 10px;
    font-weight: 700;
    line-height: 1.4;
  }
}

.marginTop {
  margin-top: 160px;
}

.backgroundImage {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &.backgroundPositionCenter {
    object-position: center;
  }

  &.backgroundPositionRight {
    object-position: right;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}
.btn {
  width: 183px;
  height: 48px;
  margin-bottom: 48px;
  padding: 16px;
  border: 2px solid #fff;
  border-radius: 30px;
  background: transparent;
  color: #fff;
  font-family: $font-axiforma;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
  @include breakpoint(medium) {
    margin-bottom: 0;
  }

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(83, 86, 90, 0.1) 0%,
        rgba(83, 86, 90, 0.1) 100%
      ),
      #fff;
    color: #111;

    svg > path {
      fill: #111;
    }
  }

  &:focus {
    outline: 2px dashed #787878;
  }
}

.epqBtn {
  margin-top: 0;
  margin-bottom: 10px;
  @include breakpoint(medium) {
    margin-right: 8px;
  }
  border: none;
  background: #de3700;
  color: $color-white;

  &:hover,
  &:focus {
    border: none;
    background: #cc461a;
    color: $color-white;
  }
}

.callNowCta {
  color: $color-dark-blue;
  background-color: white;

  &:hover,
  &:focus {
    border: none;
    background: #113c72;
    color: $color-white;

    svg > path {
      fill: $color-white;
    }
  }
}
