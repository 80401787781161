
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/tooltip.scss';
    @import '~/scss/slider.scss';
  
.addressForm {
  position: relative;
  width: 100%;

  .addressHeadline {
    text-align: left;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.4;
    font-family: $font-primary;
    margin-top: 12px;
    color: #111111;
  }

  .heroAddressHeadline {
    color: #111111;
    margin-top: 0;
  }

  .fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  .isNavFieldSet {
    display: flex;
  }

  .nonHeroFieldset {
    @include breakpoint(medium) {
      display: flex;
    }
  }

  .inputWrapper {
    display: flex;
    position: relative;
  }

  .isNavInputWrapper {
    flex-grow: 1;
  }

  .nonHeroInputWrapper {
    @include breakpoint(medium) {
      flex-grow: 1;
    }
  }

  .mapPin {
    position: absolute;
    top: 18px;
    left: 14px;
  }

  .textInput {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-left: 35px;
    border: 1px solid $color-matterhorn;
    border-radius: 4px;
    box-shadow: none;
    color: $color-medium-gray;
    font-family: $font-primary;
    font-size: 15px;
    padding-bottom: 3px;

    &:hover {
      border: 1px solid #111111;
    }

    &:focus,
    &:active {
      padding-bottom: 1px;
      border: 2px solid #111111;
    }
  }

  .textInputColor {
    &:hover {
      border: 1px solid #111111;
    }

    &:focus,
    &:active {
      padding-bottom: 1px;
      border: 2px solid #111111;
    }
  }

  .btn {
    position: relative;
    top: 16px;
    font-size: 20px;
    line-height: 20px;
    height: 52px;
    border-radius: 30px;
    font-weight: 600;
    background: #de3700;
    font-family: $font-axiforma;
    margin-bottom: 16px;
    letter-spacing: 0.8px;
    &:hover,
    &:focus {
      background: #cc461a;
      border: none;
      color: $color-white;
    }

    &:focus {
      outline: 2px dashed #787878;
    }
  }

  .uppercaseBtn {
    text-transform: uppercase;
  }

  .nonHeroBtn {
    top: 0;
    padding: 16px 24px;
    margin-top: 16px;
    margin-bottom: 0;
    @include breakpoint(medium) {
      max-width: 230px;
      margin-left: 16px;
      margin-top: 0;
    }
  }

  .isNavBtn {
    top: 0;
    padding: 16px 24px;
    margin-left: 16px;
    margin-bottom: 0;
    max-width: 79px;

    @include breakpoint(medium) {
      max-width: 178px;
    }
  }

  .suggestionsList {
    position: absolute;
    z-index: 2;
    top: 59px;
    width: calc(100% - 2px);
    border: 1px solid #d1d2d4;
    background-color: $color-white;
    text-align: left;

    .suggestionsListItems {
      max-height: 189px;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      overflow-y: auto;
      border: 1px solid rgb(209, 210, 212);
      background-color: $color-white;
      font-family: $font-primary;
      list-style: none;
      cursor: pointer;

      &:first-child {
        border: none;
      }
    }

    .suggestionsListClose {
      z-index: 2;
      padding: 6px;
      border: 1px solid $color-light-gray;
      background-color: $color-white;

      &:hover {
        color: rgb(43, 126, 147);
      }
    }

    .suggestionListNoApartments {
      padding: 6px;
      border: 1px solid $color-light-gray;
      color: $color-medium-gray;
      background-color: $color-white;
      text-decoration: underline;
      cursor: pointer;
    }

    .suggestionsListCloseCopy {
      margin: 0;
      color: $color-medium-gray;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .isNavContainer {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    max-width: 704px;
  }

  .nonHeroContainer {
    @include breakpoint(medium) {
      display: flex;
      flex-direction: column;
      max-width: 600px;
    }
  }

  .isModalContainer {
    @include breakpoint(medium) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
