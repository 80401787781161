$color-primary-blue: #0091c4;
$color-dark-blue: #154b8e;
$color-orange: #ff4814;
$color-warm-gray: #7a6e6b;
$color-medium-gray: #676767;
$color-neutral-gray: #fafbfd;
$color-dark-gray: #333;
$color-light-gray: #f7f7f7;
$color-black: #000;
$color-white: #fff;
$color-dark-charcoal: #333333;
$color-matterhorn: #535353;
$font-primary: 'Open Sans', arial, sans-serif;
$font-proxima: 'Proxima Nova', arial, sans-serif;
$font-axiforma: 'Axiforma', arial, sans-serif;

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  1%,
  3%,
  5%,
  7%,
  9%,
  11% {
    transform: rotate(-20deg);
  }
  2%,
  4%,
  6%,
  8%,
  10% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
