
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/tooltip.scss';
    @import '~/scss/slider.scss';
  
.container {
  max-width: 100%;
  max-height: 100%;

  .section {
    max-height: 378px;
    max-width: 375px;
    margin: 0 auto;
    background: url('/images/section/us-news-bg-mobile.jpg');
    @include breakpoint(medium) {
      max-height: 248px;
      max-width: 768px;
      background: url('/images/section/us-news-bg-tablet.jpg');
      background-size: 100% 100%;
    }
    @include breakpoint(large) {
      max-width: 1440px;
      background: url('/images/section/us-news-bg.jpg');
      background-size: 100% 100%;
    }
    .content {
      padding: 32px;
      display: flex;
      flex-direction: column;

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
      }
      @include breakpoint(large) {
        justify-content: space-around;
        align-items: center;
      }
      .headline {
        color: #fff;
        margin-bottom: 20px;
        max-width: 311px;
        max-height: 160px;
        font-family: $font-axiforma;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        @include breakpoint(medium) {
          margin-bottom: 0;
          max-width: 375px;
          font-size: 32px;
          line-height: 40px;
        }
        @include breakpoint(large) {
          max-width: 576px;
          max-height: 120px;
        }
      }

      svg {
        margin: auto 0 0 auto;
        @include breakpoint(medium) {
          margin: 0;
        }
      }
    }
  }
}
