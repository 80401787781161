/* -----------------------------------------------------------------------------
 *
 * Components: Tooltip
 *
 * ----------------------------------------------------------------------------- */

.tippy-touch {
  cursor: pointer !important;
}

[ data-tippy-root ] .tippy-content {
  // padding: 12px 16px;
  // border-radius: 4px;
  // text-align: left;

  p:last-of-type {
    // margin-bottom: 0;
  }
}

.tooltip-btn {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.tooltip-btn svg {
  fill: gray;
}

.tooltip-icon {
  cursor: pointer;
}

.tooltip-content--legal {
  // max-width: 200px;
  // margin-bottom: 0;
  font-family: 'Open Sans';
  font-size: 14px;
  // line-height: lh( 14px, 18px );
}

/* -----------------------------------------------------------------------------
* Light Theme
* ----------------------------------------------------------------------------- */

[ data-tippy-root ] .tippy-box[ data-theme='light' ] {
  background-color: white;
  box-shadow: black;
  color: grey;
}

[ data-tippy-root ] .tippy-box[ data-theme='light' ] .tippy-arrow {
  color: white;
}

/* -----------------------------------------------------------------------------
* Dark Theme
* ----------------------------------------------------------------------------- */

[ data-tippy-root ] .tippy-box[ data-theme='dark' ] {
  background-color: rgb(50, 49, 58);
  box-shadow: black;
  color: rgb(227, 232, 232);
}

[ data-tippy-root ] .tippy-box[ data-theme='dark' ] .tippy-arrow {
  color: rgb(50, 49, 58);
}
