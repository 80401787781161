.custom-dots {
  display: flex;
  justify-content: center;
  bottom: -24px !important;
  padding: 0 0 24px 0 !important;
}

.custom-dot {
  width: 10px;
  height: 10px;
  background-color: #e4e5e3;
  border-radius: 50%;
  cursor: pointer;
}

.slick-active {
  .custom-dot {
    background-color: rgb(83, 83, 83);
  }
}
